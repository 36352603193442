import React from "react";
import {
  Row,
  Col,
  Card,
} from "react-bootstrap";
import './RatesAndFees.scss';

import { ReactComponent as First } from "../../assets/images/Icons_descargar.svg";
function RatesAndSafes( ) {
  return (
    <div>
      <div className="fq-header wrapper">
        <span className="fq-title" style={{ marginTop: 60 }}>
          Tasas y Tarifas
        </span>
      </div>

      <Row style={{ padding: 30, paddingLeft: "15%", paddingRight: "15%" }}>
        <Col md={3}>
          <p className="Columna">Documentos descargables</p>
        </Col>
        <Col md={9}>
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>Tarifario de Tasas Activas</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  href={process.env.REACT_APP_TARIFARIOS_ACTIVAS}
                  rel="noopener noreferrer"
                  target="_blank"
                  download="Tarifario_Tasas_Activas"
                >
                  {" "}
                  <First /> Descargar{" "}
                </a>
              </Col>
            </Row>
          </Card>
          <br />
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>Tarifario de Comisiones y Seguros</Col>
              <Col md={3}>
                <a
                  style={{ textDecoration: "none", color: "#767676" }}
                  href={process.env.REACT_APP_TARIFARIO_COMISION_Y_SEGURO}
                  rel="noopener noreferrer"
                  target="_blank"
                  download="Tarifario_comisión_y_seguro"
                >
                  <First /> <span className="Descarga"> Descargar </span>
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default RatesAndSafes;
