import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import "./index.scss";
import { apiGetListByKeybord, apiGetListPrincipal } from "service/apiEndPoint";
import SurgirButton from "components/ui/SurgirButton/SurgirButton";
import { debounce } from 'lodash';

const SurgirSearchContainer = (props) => {
  const [tags, setTags] = useState([]);
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTopScroll, setIsTopScroll] = useState(true);
  const [opacity, setOpacity] = useState(false);

  const handleScroll = () => {
    const position = window.scrollY;
    setIsTopScroll(position === 0);
  };

  const useDebouncedCallback = (callback, delay) => {
    const d = callback;
    const callbackfunc = useCallback(debounce(d, delay), []);
    return [callbackfunc]
  }

  const changeSearch = (event) => {
    setValueSearch(event.target.value);
    if (event.target.value.trim().length >= 3) {
      setLoading(true);
      setShowOptions(true);
      debouncedFunction(event.target.value);
    } else {
      setShowOptions(false);
    }
  };

  const onkeyInput = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      search();
    }
  }

  const search = () => {
    if (showOptions && !loading) {
      localStorage.setItem(
        "search_data",
        JSON.stringify({
          value_search: valueSearch,
          data: options,
        })
      );
      createLink("/resultado-busqueda");
      props.onChangeInput && props.onChangeInput(valueSearch);
    }
  }

  const callListByKey = (key) => {
    apiGetListByKeybord({ keyword: key })
      .then((response) => {
        setLoading(true);
        return response.json();
      })
      .then((keydata) => {
        setOptions(keydata.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const [debouncedFunction] = useDebouncedCallback(callListByKey, 1000);

  const createLink = (url, target) => {
    const link = document.createElement("a");
    link.href = url;
    target && (link.target = target);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const listenScroll = () => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }

  useEffect(() => {
    setOpacity(true);
    apiGetListPrincipal()
      .then((response) => {
        return response.json();
      })
      .then((principal) => {
        setTags(principal.data);
      })
      .catch((err) => console.log(err));
      listenScroll();
      handleScroll();
  }, []);

  return (
    <div className={`static-container-search ${isTopScroll ? '' : 'container-top-responsive'}`}>
      <div className={`container-search ${opacity && 'show-buscador'}`}>
      <div className="fixed-container-search">
        <div className="container-input-search">
          <div className="input-search">
            <img className="ic-search" alt="icon-search" />
            <input
              type="text"
              aria-label="input-search"
              placeholder="Buscar en SURGIR"
              onChange={changeSearch}
              onKeyUp={onkeyInput}
              value={valueSearch}
            />
            {showOptions && (
              <img
                className="ic-clear"
                alt="icon-search-clear"
                onClick={() => {
                  setShowOptions(false);
                  setValueSearch("");
                }}
              />
            )}
          </div>
          {showOptions && (
            <div className="options-float">
              {loading && (
                <div className="container-loader">
                  <div>
                    <img alt="icon-loader" />
                  </div>
                </div>
              )}
              {!options.length && !loading && (
                <div className="container-hidden-data">
                  <img alt="icon-search-incognito" />
                  <div>
                    <span>Lo sentimos, no conseguimos lo que buscas</span>
                    <span>Prueba con una búsqueda diferente</span>
                  </div>
                </div>
              )}
              {!loading &&
                options.map((option, i) => (
                  <div
                    key={i}
                    className="item-option"
                    onClick={() => createLink(option.moreLink, '_blank')}
                  >
                    <div className="detail-option">
                      {option.isDownload && (
                        <img className={option.iconClass} alt="icon-pdf" />
                      )}
                      <div>
                        <span>{option.title}</span>
                        <span>{option.subTitle}</span>
                      </div>
                    </div>
                    {option.isDownload ? (
                      <img className="ic-download" alt="icon-download" />
                    ) : (
                      <img className="ic-arrow-right" alt="icon-right" />
                    )}
                  </div>
                ))}
            </div>
          )}
          <SurgirButton
            radius={"50px"}
            onClick={search}
            fontFamily={"Str-Microtext-Bold"}
            width="136px"
            height="48px"
            marginTop="0px"
            marginBottom="0px"
            variant="danger"
          >
            Buscar
          </SurgirButton>
        </div>
        <div className="accesos">
          <span>Accesos rápidos</span>
          <div className="container-tags">
            <div className="scroll-tags">
              {tags.map((tag, i) => (
                <a key={i} href={tag.moreLink} className="tag-label">
                  {tag.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default SurgirSearchContainer;
