import { FormHelperText } from "@mui/material";
import Checkmark from "Icons/Checkmark";
import TrashIcon from "Icons/TrashIcon";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { formatBytes } from "utils/functions";
import IcInfo from '../../../assets/images/ic_info.svg';
import SurgirButton from "../SurgirButton/SurgirButton";
import { SurgirModalConfirm } from "../SurgirModal/SurgirModalConfirm";
import SurgirSpinner from "../SurgirSpinner/SurgirSpinner";
import { SurgirTooltip } from "../SurgirTooltip/SurgirTooltip";
import './SurgirFileUpload.scss';

const ContainerFileUpload = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const WrapperFileUpload = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (min-width: 375px) {
    gap: 13px;
  }

  @media (min-width: 768px) {
    width: 100%;
  }
`;

const WrapperSize = styled.div`
  font-family: "Str-Microtext-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767676;
  margin-bottom: 8px;
  padding-left: 6px;
`;
const WrapperText = styled.div`
  width: 224px;
  word-wrap: break-word;
`;

export const ContainerBtnAdjuntar = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 14px;

  @media (min-width: 768px) {
    width: 544px;
  }
`;

const DividerLine = styled.div`
  background: #cccccc;
  width: 100%;
  height: 1px;
  margin-top: 17px;
`;
const FileContainer = styled.div`
  width: 224px;
  @media (min-width: 375px) {
    width: 100%;
    max-width: 488px;
  }
`;

const ContainerSize = styled.div`
  display: flex;
`;

interface SurgirFileUploadProps {
  size: number;
  titleButton: string;
  dataFile: [];
  getFiles: any;
  toolTip: boolean;
  titleTooltip: string;
  descriptionTooltip: string;
  validateError?: any;
  typeFiles?: [];
  id?: string;
  name?: string;
}

const SurgirFileUpload: React.FC<SurgirFileUploadProps> = ({
  size,
  titleButton,
  dataFile = [],
  getFiles,
  toolTip,
  titleTooltip,
  descriptionTooltip,
  validateError,
  id,
  name,
  typeFiles = ['image/png', 'image/jpeg','image/jpg']
}) => {
  const [file, setFile] = useState([] as any);
  const inputFile = useRef(null as any);
  const [status, setStatus] = useState(null as any);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [indexselected, setIndexselected] = useState(null);
  const [error, setError] = useState(false)
  const [messageError, setMessageError] = useState("")
  const handleModalSuccessClose = () => {
    setVisibleModalSuccess(false);
  };

  useEffect(() => {
    if (dataFile.length) {
      setFile(dataFile);
    }
  }, []);

  const handleChange = (e: any) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      if (validType(selectedFile)) {
        const fileSize = selectedFile.size / 1024 / 1024;
        if (fileSize < 2) {
          if (file.length < size) {
            file.push(selectedFile);
            setFile((current: any) =>
              current.map((obj: any) => {
                return obj;
              })
            );
            setStatus(file.length - 1);
            getFiles(file);
            setTimeout(() => {
              setStatus(null);
            }, 3000);
          } else {
            setError(true)
            setMessageError("Solo está permitido cargar 3 archivos")
          }
        } else {
          setError(true)
          setMessageError("No está permitido subir archivos mayores a 2 MB")
        }
      }

    }
  };

  const validType = (file: any) => {

    const fileType = file.type;
    return typeFiles.some(x => x === fileType);
  }

  const handleDelete = (status: boolean) => {
    if (status) {
      file.splice(indexselected, 1);
      setFile((current: any) =>
        current.map((obj: any) => {
          return obj;
        })
      );
      getFiles(file);
      setVisibleModalSuccess(false);
    } else {
      setVisibleModalSuccess(false);
      setIndexselected(null);
    }
  };

  const openModalConfirm = (index: any) => {
    setIndexselected(index);
    setVisibleModalSuccess(true);
  }

  return (
    <>
      <ContainerBtnAdjuntar>
        <SurgirButton
          variant={"outline"}
          color="error"
          border={"1px solid #EC0000"}
          radius="24px"
          width={"100%"}
          height={"48px"}
          colorText={"#EC0000"}
          onClick={() => {
            inputFile.current.click();
            setError(false)
          }}
        >
          {titleButton ?? "Adjuntar"}
        </SurgirButton>
        {toolTip && (
          <SurgirTooltip
            title={titleTooltip ?? "Info"}
            description={descriptionTooltip}
          >
            <img
              style={{ width: "20px", height: "20px" }}
              src={IcInfo}
              alt="iconinfo"
            />
          </SurgirTooltip>
        )}
        <div className="textOptionalFile">Opcional. Máximo 3 archivos de 2 MB PNG, JPG o JPEG.</div>

      </ContainerBtnAdjuntar>
      {error && <FormHelperText error variant='filled' >{messageError}</FormHelperText>}
      <label htmlFor={id ?? "upload-input"} hidden>upload File</label>
      <input type="file" id={id ?? "upload-input"} name={name} onChange={handleChange} ref={inputFile} hidden accept={typeFiles as any} />
      <SurgirModalConfirm
        show={visibleModalSuccess}
        handleClose={handleModalSuccessClose}
        setconfirm={handleDelete}></SurgirModalConfirm>

      {file.map((item: any, index: number) => (
        <ContainerFileUpload key={item.name}>
          <WrapperFileUpload>
            <FileContainer>
              <WrapperText>
                <span>{item.name}</span>
              </WrapperText>

              <ContainerSize>
                {
                  status === index ?
                    (<SurgirSpinner />)
                    : (<Checkmark />)
                }
                <WrapperSize>{`${formatBytes(item.size)}`}</WrapperSize>
              </ContainerSize>

              <div></div>
            </FileContainer>
            <button
              className="containet-icon"
              data-testid={`containerId${index}`}
              key={item.size}
              onClick={() => openModalConfirm(index)}
              style={{
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                padding: 0,
                textDecoration: 'none',
              }}
              type="button"
            >
              <div>
                <TrashIcon size={20} color={"#EC0000"} />
              </div>
            </button>
          </WrapperFileUpload>
          {file.length - 1 !== index && <DividerLine></DividerLine>}
        </ContainerFileUpload>
      ))}
    </>
  );
};

export default SurgirFileUpload;
