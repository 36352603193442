import React from 'react';
import Bannerhome1 from './../../assets/images/form_dpf/dpf-form.png';
import Santander from './../../assets/images/form_dpf/santander-logo-rojo.svg';
import './FormDepositFixed.scss';

const BannerFormDpf: React.FC = () => {
  return (
    <div className="dpf-container-banner" >
          <div>
            <center>
            <span className="dpf-text-banner">¡Depósito a Plazo Fijo de tus sueños!<br />
            </span>
            <p className="description-banner-dft">
              ¡Llegamos donde estés con una <strong>súper tasa</strong>!
              </p>
            </center>
          </div>
        
          <div>
            <center>
                <img className="dpf-image"  src={Bannerhome1} alt="Llegamos a Arequipa y Piura Banner Home SURGIR" />
            </center>
          </div>
          <br />
          <br />
          <div >
            <center>
              <span className="banner-text" >Con el respaldo de</span>
              <br />
              <img src={Santander} />
            </center>
          </div>
    </div>
  );
}

export default BannerFormDpf;
