import { SafeTypes, TablePlan, Plan, Operation, Coverage } from "domain/models/SurgirSafe"

export const SAFE_TYPES: SafeTypes[] = [
  {
    title: 'Muerte natural',
    description: 'Fallecimiento del asegurado por causas distintas a un accidente.',
    showDescription: false,
    className: "card-one",
    classNameSecondary: "card-one-dark",
    alt: 'Muerte Natural Super Seguro Cobertura Seguro Vida'
  },
  {
    title: 'Muerte accidental',
    description: 'Fallecimiento del asegurado como consecuencia directa e inmediata de un accidente.',
    showDescription: false,
    className: "card-two",
    classNameSecondary: "card-two-dark",
    alt: 'Muerte Accidental Super Seguro Cobertura Seguro Vida'
  },
  {
    title: 'Muerte accidental en transporte público',
    description: 'Fallecimiento del asegurado por un accidente en un medio de transporte público terrestre con rutas preestablecidas.',
    showDescription: false,
    className: "card-three",
    classNameSecondary: "card-three-dark",
    alt: 'Muerte Accidental en transporte público Super Seguro Cobertura Seguro Vida'
  },
  {
    title: 'Indemnización diaria por hospitalización',
    description: 'Cuando el asegurado sea hospitalizado a consecuencia de un accidente o de una enfermedad, se le pagará la renta diaria indicada en la póliza.',
    showDescription: false,
    className: "card-four",
    classNameSecondary: "card-four-dark",
    alt: 'Indemnización diaria por hospitalización Super Seguro Cobertura Seguro Vida'
  },
  {
    title: 'Desamparo súbito familiar',
    description: 'Si a consecuencia de un mismo accidente, amparado por el presente seguro, fallecieran el asegurado y su cónyuge.',
    showDescription: false,
    className: "card-five",
    classNameSecondary: "card-five-dark",
    alt: 'Desamparo súbito familiar Super Seguro Cobertura Seguro Vida'
  }
  ,{
    title: 'Indemnización por sepelio',
    description: 'Reembolso de los gastos de sepelio en los que se hubiera incurrido como consecuencia del fallecimiento del asegurado.',
    showDescription: false,
    className: "card-six",
    classNameSecondary: "card-six-dark",
    alt: 'Indemnización por sepelio Super Seguro Cobertura Seguro Vida'
  }
]

export const TABLE_PLANS: TablePlan[] = [
  {
    icon: 'telefonica',
    titlePlan: 'Crédito Prospera o Crédito Construyendo Sueños',
    values : [
      {
        title: 'Precio:',
        amount: '0.10% mensual del saldo capital*',
        description: false,
        id: 19
      },
      {
        title: 'Precio:',
        amount: '0.50% mensual del saldo capital',
        description: false,
        id: 20
      }
    ]
  },
  {
    icon: 'martillo',
    titlePlan: 'Crédito Mujeres Unidas',
    values : [
      {
        title: 'Precio:',
        amount: '0.17% mensual del saldo capital',
        description: false,
        id: 22
      },
      {
        title: 'Precio:',
        amount: '--',
        description: false,
        id: 23
      }
    ]
  },
  {
    icon: 'enchufe',
    titlePlan: 'Monto máximo asegurado',
    values : [
      {
        title: 'Hasta:',
        amount: 's/ 300,000.00',
        description: false,
        id: 25
      },
      {
        title: 'Hasta:',
        amount: 's/ 300,000.00',
        description: false,
        id: 26
      }
    ]
  }
]

export const PLANS: Plan[] = [
  { title: 'Seguro de Desgravamen Porcentual', amount: '0.10% mensual', description: ''},
  { title: 'Seguro de Desgravamen Porcentual con devolución', amount: '0.50% mensual', description: ''},
]

export const OPERATIONS: Operation[] = [
  { title: 'Mario adquiere el Seguro de Desgravamen Porcentual' , description: 'Al solicitar un crédito de S/ 10,000 en 10 meses, Mario adquirió un Seguro de Desgravamen Porcentual que le cobra el primer mes S/ 10.' },
  { title: 'Un cliente puntual con su crédito', description: 'Mario está en el quinto mes de su crédito y viene pagando puntualmente, y a la fecha solo debe S/ 5,000 de saldo capital.' },
  { title: 'Mario podría dejar endeudada a su familia', description: 'Él podría perder la vida en un accidente, dejando a su esposa e hijo con la deuda de su crédito.' },
  { title: 'Su familia quedaría endeudada', description: 'Gracias a su Seguro de Desgravamen Porcentual, la aseguradora cubriría los S/ 5,000 de saldo capital, dejando sin deuda a su esposa e hijo.' },
]

export const COVERAGE: Coverage[] = [
  { 
    icon: 'people-nature',
    title: 'Muerte natural',
    description: 'Fallecimiento del asegurado por causas distintas a un accidente.',
  },
  { 
    icon: 'places-transport',
    title: 'Muerte accidental',
    description: 'Fallecimiento del asegurado como consecuencia directa o inmediata de un accidente. ',
  },
  { 
    icon: 'wheelchair',
    title: 'Invalidez total, permanente y definitiva por accidente o enfermedad',
    description: 'Pérdida o disminución física o intelectual de su capacidad de trabajo.',
  },
  { 
    icon: 'dollar',
    title: 'Sobrevivencia*',
    description: 'Devolución de la prima establecida por el porcentaje de devolución en caso de sobrevivencia del asegurado.',
  }
]

export const TYPESINSURANCE = [
  {
    icon: 'hands-financing-red',
    title: 'Seguro de Desgravamen Porcentual',
    description: 'Cubre tu deuda vigente en caso de fallecimiento o invalidez total y permanente.'
  },
  {
    icon: 'payment-dollar',
    title: 'Seguro de Desgravamen Porcentual con Devolución',
    description: 'Devolución de hasta el 40% de la prima recaudada en caso de sobrevivencia.'
  }
]