const LinkRoutes = {
  app: "/",
  creditWomen: '/prestamo-solidario',
  resultSearchView: '/resultado-busqueda',
  creditProsperous: '/credito-prospera',
  formDepositFixed: '/abre-tu-deposito-a-plazo-fijo',
  responseForm: '/deposito-a-plazo-respuesta',
  promotionHistory: '/historial-de-promociones',
  transparency: '/transparencia',
  legal:"/legales",
  complaintsBook: '/reclamos-y-requerimientos',
  flexibleFixedDeposit: '/deposito-a-plazo-fijo-flexible',
  aboutUs: "/quienes-somos",
  ratesAndFees:"/tasas-y-tarifas",
  // creditInsurance: '/seguro-desgravamen',
  creditInsurancePercentage: '/seguro-desgravamen-porcentual',
  safe:"/super-seguro",
  contact: "/contactanos",
  faqs: "/preguntas-frecuentes",
  dpf:"/deposito-a-plazo-fijo",
  whereWeAre:"/donde-estamos",
  channels: "/canales",
  listOfNotaries:"/listado-de-notarios",
  referidos:"/refiere-y-gana",
  simulator:"/simulador",
  depositFixedSantander: '/surgirzonasegura_prospecto_deposito-a-plazo-fijo',
  formdpfLarge: "/formulario-dpf-extendido",
  savingsAccount: '/cuenta-de-ahorros',
  requestCredit: '/solicita-tu-credito',
  responseFormRequestCredit: '/solicita-tu-credito-respuesta'
};

export default LinkRoutes;
