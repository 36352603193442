
import React from "react";
import DownloadIcon from '../../../assets/icons/download-icon.svg';
import './SurgirDownloadIcon.scss';

interface SurgirDownloadIconProps {
  icon?: any ; 
  text: string ; 
  href?: any ; 
  click?: () => void;
	underline?: boolean
}

const SurgirDownloadIcon: React.FC<SurgirDownloadIconProps> = ({
  icon,
	text,
	href,
	click,
	underline
 }) => {
	return (
		<a
			className="download-icon"
			style={{textDecoration: underline ? 'underline' : 'none'}}
			href={href}
			rel="noopener noreferrer"
			target="_blank"
			download="nombre_del_file"
			onClick={click}
		>
			<img className="download-icon__icon" src={icon ?? DownloadIcon} alt={'Descargar'} />
			<span >{text}</span>
		</a>
	);
};

export default SurgirDownloadIcon;