import React from 'react';

interface SurgirItemListWithIconProps {
    icon: any;
    document: any;
    name: any;
    downloadName: string;
}
const SurgirItemListWithIcon: React.FC<SurgirItemListWithIconProps> = ({ icon, document, name, downloadName }) => {
    return (
        <div style={{ margin: "20 0 20 0", display: 'flex', alignItems: 'center' }} >
            <div>
                <img alt='icon' src={icon}  />
            </div>
            <div style={{ paddingLeft: 10 }}>
                <a className="Descarga"
                    href={document}
                    rel="noopener noreferrer"
                    target="_blank"
                    download={downloadName}
                >
                    {name}
                </a>
            </div>
        </div>
    );
}
export default SurgirItemListWithIcon;