/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { StylesSurgirTextField } from 'utils/StylesSurgirTextField';

interface SurgirTextFieldProps {
  validateError: (value: string, dependValue?: any) => { error: boolean; message?: any };
  visibleError?: boolean;
  value: string;
  dependValue?: any;
  setValue: (value: string) => void;
  label: string;
  fullWidth?: boolean;
  variant: any;
  type?: string;
  disabled?: boolean;
  FormatCustom?: boolean;
  pattern?: any;
  prefix?: string;
  id?: string;
  name?: string;
}


const NumericFormatCustomInput = ({ label, ...props }: any) => (
  <StylesSurgirTextField
    {...props}
    onChange={(e: any) => {
      if (props.onChange) {
        props.onChange(e);
      }
    }}
    inputProps={{ maxLength: props.prefix === 'S/' ? 12 : 11, autoComplete: 'off' }}
    fullWidth={true}
    variant={'filled'}
    label={'Monto'}
  />
);

const SurgirTextField: React.FC<SurgirTextFieldProps> = ({
  validateError,
  visibleError,
  value,
  dependValue,
  setValue,
  label,
  fullWidth,
  variant,
  type,
  disabled,
  FormatCustom = false,
  pattern,
  prefix,
  id,
  name
}) => {
  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (visibleError) {
      handleError(value);
    }
  }, [visibleError]);

  useEffect(() => {
    if (dependValue != undefined) {
      setError(false);
    }
  }, [dependValue]);

  const handleError = (valueTarget: string) => {
    const validation = dependValue
      ? validateError(valueTarget, dependValue.value)
      : validateError(valueTarget);

    setError(validation.error);
    setMessageError(validation.message);
    cleanSpace(valueTarget);
  };

  const cleanSpace = (valueTarget: string) => {
    setValue(valueTarget.trim());
  };

  const handleChange = ({ target }: any) => {
    if (value !== null && target.value.length >= value.length) {
      if (pattern) {
        const data = target.value;
        const dataSubstring = data[data.length - 1];
        const cond1 = pattern.test(data);

        pattern.lastIndex = 0;
        const cond2 = pattern.test(dataSubstring);
        pattern.lastIndex = 0;
        if (cond1 && cond2) {
          setValue(target.value);
          setError(false);
        }
      } else {
        setValue(target.value);
        setError(false);
      }
    } else {
      setValue(target.value);
      setError(false);
    }
  };

  return (
    <>
      {!FormatCustom ? (
        <StylesSurgirTextField
          autoComplete="off"
          helperText={error ? messageError : ""}
          error={error}
          value={value}
          onChange={handleChange}
          onClick={(e: any) => {
            if (disabled) {
              handleError(e.target.value);
            }
          }}
          label={label}
          fullWidth={fullWidth}
          variant={variant}
          inputProps={{
            maxLength: 100,
            autoComplete: 'off',
            id: id,
            name: name,
            'data-testid': 'sg-txtfld',
          }}
          type={type}
          disabled={disabled}
          onBlur={() => handleError(value)}
          onCopy={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
        />
      ) : (
        <NumericFormat
          id={id}
          name={name}
          thousandSeparator
          onBlur={e => setValue(e.target.value)}
          customInput={NumericFormatCustomInput}
          valueIsNumericString
          disabled={disabled}
          prefix={prefix}
          value={value}
        />
      )}
    </>
  );
};

export { SurgirTextField };
