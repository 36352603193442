import React from 'react';
import './SurgirCardRedirect.scss'

interface SurgirCardRedirectProps {
  image: string;
  title: string;
  description: string;
  alt: string;
  link: string;
}

const SurgirCardRedirect: React.FC<SurgirCardRedirectProps> = ({
  image, title, description, alt, link
}) => {

  const handleClickCard = () => {
    window.open(link, '_blank');
  };


  return (
    <button 
      aria-label='button'
      className="card-redirect"
      onClick={handleClickCard}
    >
      <div className="card-redirect__hero ">
        <img
          alt={alt}
          src={`images/home/products/${image}`}
        />
      </div>
      <div className="card-redirect__content"  >
        <div className="card-redirect__content__title">
          {title}
        </div>
        
        <div className="card-redirect__content__description">
          {description}
        </div>
       
        <div className='card-redirect__content__button'>
          <div>
            Ver más <img src="icons/home/chevron-right-small-red.svg" alt="" />
          </div>
        </div>
      </div>
    </button>
  );
};

export default SurgirCardRedirect;