import React, { useState } from 'react';
import { Card, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import SurgirButton from 'components/ui/SurgirButton/SurgirButton';
import SpeechBubble from "../../assets/images/speech.webp";
import TareaCompletada from "../../assets/images/tarea-completada.svg";
import Money from "../../assets/images/money.svg";


import Clock from "../../assets/images/wall-clock.svg";
import TwoHands from "../../assets/images/two-hands.svg";
import HandMoney from "../../assets/images/pictogramas.svg";
import Porki from "../../assets/images/porki.svg";

import DownloadIcon from "../../assets/images/Icons_descargar.svg";

import "./CreditWomen.scss";
import { Link } from 'react-router-dom';

import First from "./../../assets/images/first-item.svg";
import Second from "./../../assets/images/second-item.svg";
import Third from "./../../assets/images/third-item.svg";

import { SurgirModalDownload } from 'components/ui/SurgirModalDownload/SurgirModalDownload';

import PDF from "../../assets/images/pdf.svg";
import XLS from "../../assets/images/XLS.svg";
import SurgirItemCard from 'components/ui/SurgirItemCard/SurgirItemCard';
import { useMediaQuery } from '@mui/system';
import SurgirAccordion from 'components/ui/SurgirAccordion/SurgirAccordion';
import SurgirTabs from 'components/ui/SurgirTabs/SurgirTabs';

interface ItemTabProps {
  title: string;
  subtitle?: string;
  list?: any;
  last?: boolean;
}

interface ItemTab2Props {
  list: any;
  last: boolean;
}

const LinkModi = ({ to, text }: { to: string, text: string }) => {
  return (
    <Link to={to} style={{ color: "#257fa4" }}>
      {text}
    </Link>
  );
};
const ItemImage = ({ image, text }: any) => {
  return (
    <Row>
      <div style={{ display: "flex", width: "50%" }}>
        <img alt='' src={image} />
      </div>
      <div
        style={{
          backgroundColor: "#eff6f9",
          display: "flex",
          width: "50%",
          alignItems: "center",
        }}
      >
        {text}
      </div>
    </Row>
  );
};

const ItemTab = ({ title, subtitle, list, last=false}: ItemTabProps) => {
  return (
    <Row style={{ marginBottom: 30 }}>
      <Col md={4}>
        <span style={{ display: "block", fontSize: 25, color: "#1d252d" }}>
          +{title}
        </span>
        <span style={{ display: "block" }}>{subtitle}</span>
      </Col>
      <Col md={8}>
        <ul
          style={{
            borderBottomStyle: "solid",
            borderBottomColor: "#cccccc",
            paddingTop: 10,
            paddingBottom: 40,
            paddingLeft: 20,
            borderBottomWidth: last ? 0 : 2,
          }}
        >
          {list.map((item: any) => {
            return (
              <li
                key={item}
                className={"list-tab-item"}
                style={{
                  listStyleType: "initial",
                  listStylePosition: "outside",
                }}
              >
                {item}
              </li>
            );
          })}
        </ul>
      </Col>
    </Row>
  );
};


const ItemTab2 = ({ list, last }: ItemTab2Props ) => {
  return (
    <ul
      style={{
        borderBottomStyle: "solid",
        borderBottomColor: "#cccccc",
        paddingTop: 10,
        paddingBottom: 2,
        paddingLeft: 20,
        borderBottomWidth: last ? 0 : 2,
      }}
    >
      {list.map((item: any) => {
        return (
          <li
            key={item}
            className={"list-tab-item"}
            style={{ listStyleType: "initial", listStylePosition: "outside" }}
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
};

const CreditWomen: React.FC = () => {
  const [visibleModalSimulador, setVisibleModalSimulador] =
  useState(false);
  const mobileTab = useMediaQuery('(max-width: 767px)');
  
  const handleSurgirButtonClick = () => {
    setVisibleModalSimulador(true);
  };

  const handleModalSimuladorClose = () => {
    setVisibleModalSimulador(false);
  };

  const TABS_CREDIT_WOMEN = [
    {
      title: 'Ventajas',
      eventKey: 'ventajas',
      body: (
        <div>
          <p className="parrafo" style={{ marginBottom: 30 }}>
                En SURGIR queremos seguir impulsando más sueños, más familias y,
                sobre todo, a más emprendedoras como tú. Por eso te traemos el
                crédito Mujeres Unidas con más beneficios…
              </p>
              <ItemTab
                key={0}
                title={"Justo"}
                subtitle={"Más ahorro para tu bolsillo"}
                list={[
                  "Con una de las tasas y cuotas más bajas en el mercado.",
                  "¡Olvídate de pagar dos cuotas al mes! Ahora paga solo una cuota al mes y ten más tiempo para reinvertir tu dinero en tu negocio y mercadería.",
                  "No solicitamos ahorro inicial ni mensual.",
                  "Elige la Fecha de tu primer pago.",
                  "Incluye seguro de Desgravamen para no dejar desprotegida a tu familia en caso de muerte o accidente temporal.",
                ]}
              />
              <ItemTab
                key={1}
                title={"Sencillo"}
                subtitle={"Tendrás más tiempo para tu negocio y familia"}
                list={[
                  "Evaluación sencilla y rápida, sin garantía prendaria y sin trámites ni papelería engorrosa.",
                  "Forma tu grupo rápidamente, desde 6 hasta 8 mujeres y que  se conozcan entre todas.",
                  "Crédito 100% digital, Obtén tu crédito sin salir de casa o negocio.",
                  "Sin reuniones semanales con tu asesor.",
                  "Paga solo una cuota al mes y ten más tiempo para conseguir el monto de tu cuota.",
                ]}
              />
              <ItemTab
                key={2}
                title={"Personal"}
                subtitle={
                  "Más asesorías para acelerar tu crecimiento y el de tu negocio"
                }
                list={[
                  "Te acompañamos en tu progreso, brindándote asesoría continua en tus finanzas personales y de tu negocio.",
                ]}
              />
              <ItemTab
                key={3}
                title={"Confianza"}
                subtitle={"Porque confiamos más en ti"}
                list={[
                  "Ten la libertad de organizarte con tu grupo para reunir tu cuota del mes.",
                  "Flexibilidad para reunirte con tu grupo.",
                  "Tú misma eliges tu grupo. Y Recuerda que se conozcan entre todas.",
                ]}
              />
              <ItemTab
                key={4}
                title={"Oportunidades"}
                last={true}
                subtitle={"Para hacer crecer tu negocio ¡hoy!"}
                list={[
                  "Si estás  mal reportada en la central de Riesgo, te damos la posibilidad de acceder a este préstamo.",
                  "De acuerdo a tu buen comportamiento y capacidad de pago podrás mejorar tu calificación crediticia en Centrales Privadas de Riesgo",
                  "Podrás solicitar la renovación e incrementos de tu crédito anticipadamente.",
                ]}
              />
        </div>
      )
    },
    {
      title: 'Características',
      eventKey: 'caracteristicas',
      body: (
        <div>
          <Row>
                <Col md={4}>
                  <p className="Columna">Monto</p>
                </Col>
                <Col md={8}>
                  <ItemTab2
                    key={0}
                    list={["Préstamos desde S/ 1,000.00 hasta S/ 10,000.00."]}
                    last={true}
                  />

                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="Columna">Pagos de cuota</p>
                </Col>
                <Col md={8}>
                  <ItemTab2
                    key={1}
                    list={["Paga solo una cuota al mes.", "Pago de cuota fija."]}
                    last={true}
                  />
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="Columna">Plazo</p>
                </Col>
                <Col md={8}>
                  <ItemTab2
                    key={3}
                    list={["Plazo de crédito por 4 meses."]}
                    last={true}
                  />

                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="Columna">Formación de grupos</p>
                </Col>
                <Col md={8}>
                  <ItemTab2
                    key={4}
                    list={[
                      "Forma tu grupo desde 6 hasta 8 mujeres y que se conozcan entre todas.",
                      "Podrás incluir a  personas que no cuenten con una buena calificación en el sistema financiero. ",
                    ]}
                    last={true}
                  />
                </Col>
              </Row>
        </div>
      )
    },
    {
      title: 'Requisitos',
      eventKey: 'requisitos',
      body: (
        <div>
          <Row>
                <Col md={4}>
                  <p className="Columna">Condición</p>
                </Col>
                <Col md={8}>
                  <ItemTab2
                    key={0}
                    list={[
                      <span key={0}>
                        Dirigido para mujeres con negocio. <br /> Pudiendo ser
                        estacional o por oportunidad.
                      </span>,
                    ]}
                    last={true}
                  />

                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="Columna">Documentación</p>
                </Col>
                <Col md={8}>
                  <ItemTab2
                    key={1}
                    list={[
                      "Recibo de servicios: luz, agua u otros.",
                      "Documento oficial de identidad (DOI).",
                    ]}
                    last={true}
                  />
                </Col>
              </Row>
        </div>
      )
    },
    {
      title: 'Tasas y Tarifas',
      eventKey: 'tasasytarifas',
      body: (
        <div>
          <Row style={{ padding: 40 }}>
                <Col md={3}>
                  <p className="Columna">Tasas</p>
                </Col>
                <Col md={9}>
                  <Card body>
                    {" "}
                    <Row>
                      <Col md={9}>Tarifario de Tasas Activas</Col>
                      <Col md={3}>
                        <a
                          style={{ textDecoration: "none", color: "#767676" }}
                          href={process.env.REACT_APP_TARIFARIOS_ACTIVAS}
                          target="_blank"
                          download="Tarifario_Tasas_Activas"
                          className='download-url'
                        >
                          {" "}
                          <img src={DownloadIcon} alt="" />  Descargar{" "}
                        </a>
                      </Col>
                    </Row>
                  </Card>
                  <br />
                </Col>
              </Row>
              <Row style={{ padding: "1px 40px 40px 40px" }}>
                <Col md={3}>
                  <p className="Columna">Comisiones y penalidades</p>
                </Col>

                <Col md={9}>
                  <Card body>
                    {" "}
                    <Row>
                      <Col md={9}>Tarifario de Comisiones y Seguros</Col>
                      <Col md={3}>
                        <a
                          style={{ textDecoration: "none", color: "#767676" }}
                          href={
                            process.env.REACT_APP_TARIFARIO_COMISION_Y_SEGURO
                          }
                          rel="noopener noreferrer"
                          target="_blank"
                          download="Tarifario_comisión_y_seguro"
                          className='download-url'
                        >
                          <img src={DownloadIcon} alt="" /> Descargar
                        </a>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
        </div>
      )
    },
    {
      title: 'Documentación',
      eventKey: 'documentación',
      body: (
        <Row style={{ padding: 40 }}>
        <Col md={3} >
          <p className="Columna">Documentos descargables</p>
        </Col>
        <Col md={9}>
          <Card body>
            {" "}
            <Row>
              <Col md={9}>Contrato de crédito Mujeres Unidas</Col>
              <Col md={3}>
                <a
                  style={{ textDecoration: "none", color: "#767676" }}
                  href={process.env.REACT_APP_ARCHIVO_DE_CONTRATO}
                  rel="noopener noreferrer"
                  target="_blank"
                  download="Contrato_de_crédito"
                  className='download-url'
                >
                  {" "}
                  <img src={DownloadIcon} alt="" /> Descargar{" "}
                </a>
              </Col>
            </Row>
          </Card>
          <br />
          <Card body>
              {" "}
              <Row>
                <Col md={9}>Beneficios, riesgos y condiciones - Operaciones activas</Col>
                <Col md={3}>
                  <a
                    className="Descarga download-url"
                    style={{ textDecoration: "none", color: "#767676" }}
                    href={process.env.REACT_APP_BENEFICIOS_RIESGOS_CONDICIONES}
                    rel="noopener noreferrer"
                    target="_blank"
                    download="Contrato_de_crédito"
                  >
                    {" "}
                    <img src={DownloadIcon} alt="" /> Descargar{" "}
                  </a>
                </Col>
              </Row>
            </Card>
        </Col>
      </Row>
      )
    },
    {
      title: 'Simulador y Fórmulas',
      eventKey: 'simulador',
      body: (
        <Row style={{ padding: 40 }}>
                <Col md={3}>
                  <p className="Columna">Documentos descargables</p>
                </Col>
                <Col md={9}>
                  <Card body>
                    {" "}
                    <Row>
                      <Col md={9}>Simulador de crédito</Col>
                      <Col md={3}>
                        <a
                        className="Descarga download-url"
                          style={{ textDecoration: "none", color: "#767676" }}
                          href={process.env.REACT_APP_ARCHIVO_DE_SIMULADOR_MUJERES}
                          rel="noopener noreferrer"
                          target="_blank"
                          download="Contrato_de_crédito"
                        >
                          {" "}
                          <img src={DownloadIcon} alt="" /> Descargar{" "}
                        </a>
                      </Col>
                    </Row>
                  </Card>
                  <br />
                  <Card body>
                    {" "}
                    <Row>
                      <Col md={9}>Fórmulas y ejemplos explicativos</Col>
                      <Col md={3}>
                        <a
                     className="Descarga download-url"

                          style={{ textDecoration: "none", color: "#767676" }}
                          href={process.env.REACT_APP_ARCHIVO_DE_SIMULADOR_INFORMATIVO_MUJERES}
                          rel="noopener noreferrer"
                          target="_blank"
                          download="Contrato_de_crédito"
                        >
                          {" "}
                          <img src={DownloadIcon} alt="" /> Descargar{" "}
                        </a>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
      )
    },
    {
      title: 'Soluciones Financieras',
      eventKey: 'soluciones',
      body: (
        <>
          <Row style={{ padding: 20 }}>
            <Col md={3}>
              <p className="Columna">Refinanciamiento</p>
            </Col>
            <Col md={9}>
              <Card body>
                {" "}
                <Row>
                  <Col md={9}>Refinanciamiento de Créditos</Col>
                  <Col md={3}>
                    <a
                      className="Descarga download-url"
                      style={{ textDecoration: "none", color: "#767676" }}
                      href={process.env.REACT_APP_INFORMATIVO_REFINANCIAMIENTO_DE_CREDITO}
                      rel="noopener noreferrer"
                      target="_blank"
                      download="Contrato_de_crédito"
                    >
                      {" "}
                      <img src={DownloadIcon} alt="" /> Descargar{" "}
                    </a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row style={{ padding: 20 }}>
            <Col md={3}>
              <p className="Columna">Impulso MYPERÚ</p>
            </Col>
            <Col md={9}>
              <Card body>
                {" "}
                <Row>
                  <Col md={9}>Impulso MYPERÚ</Col>
                  <Col md={3}>
                    <a
                      className="Descarga download-url"
                      style={{ textDecoration: "none", color: "#767676" }}
                      href={process.env.REACT_APP_INFORMATIVO_IMPULSO_MYPERU}
                      rel="noopener noreferrer"
                      target="_blank"
                      download="Impulso_MYPERÚ"
                    >
                      {" "}
                      <img src={DownloadIcon} alt="" /> Descargar{" "}
                    </a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )
    },
  ];

  const [hiddenSection] = useState(true)

  return (
    <div>   
      <div style={{ paddingTop: 55 }}>
        <div className="banner-mujeres">
          <Container>
            <span className="textBannerM" >Crédito Mujeres Unidas<br />
            <span className="subTextBannerM">Acelera tu progreso y el de tu negocio  </span> 
            <SurgirButton 
              onClick={handleSurgirButtonClick}
              radius={'32px'}
              fontFamily={"Str-Microtext-Bold"} 
              width= '160px' 
              height= '40px' 
              marginTop= "20px"
              variant="danger" 
            >
              Simula tu crédito
            </SurgirButton>
            </span>
          </Container>
        </div>
      </div>
      <div className="segment-container">
        <div className="title-segment-content-solidario">
          <span className="title-general-segment">
            Obtén tu crédito sin salir de casa o negocio
          </span>
        </div>
        <div className="body-segment-content-solidario-cw">
          <Row>
            <Col md={{ span: 4 }}>
              <SurgirItemCard
                 text={`Llámanos al (01) 480 0123 o acércate a una de nuestras agencias y te atenderemos.`}
                 position={'1'}
                 width={80}
                 title={"Contacto"}
                 image={<img alt='' src={SpeechBubble} />}
              ></SurgirItemCard>
            </Col>
            <Col md={{ span: 4 }}>
              <SurgirItemCard 
                text={`Programamos tu reunión para llenar la propuesta, verificar tu documentación y darte respuesta sobre la aprobación de tú crédito.`}
                position={'2'}
                width={80}
                title={"Evaluación y aprobación"}
                image={<img alt='' src={TareaCompletada} />}
              />
            </Col>
            <Col md={{ span: 4 }}>
              <SurgirItemCard 
                text={`Te desembolsamos en tu cuenta de ahorros para que no tengas que salir de casa. Además, podrás elegir el canal más conveniente para el pago de tus cuotas.`}
                position={'3'}
                width={80}
                title={"Desembolsos rápidos y prácticos"}
                image={<img alt='' src={Money} />}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="segment-container">
        <div className="title-segment-content-solidario">
          <span className="title-general-segment">
            Conoce los Beneficios de tu Crédito Mujeres Unidas
          </span>
        </div>
        <div className="body-segment-content-solidario-cw">
          <Row>
            <Col md={{ span: 3 }}>
              <SurgirItemCard 
                text={`Préstamos  con una de las  tasas y cuotas más bajas del mercado. Solicita tu préstamo aquí y desembolsa al toque.`}
                title={"Crédito barato y rápido"}
                width={95}
                image={<img alt='' src={Clock} />}
              />
            </Col>
            <Col md={{ span: 3 }}>
              <SurgirItemCard 
                text={`Oportunidad de acceder a un préstamo grupal para personas mal reportadas en centrales de riesgo.`}
                width={95}
                title={"Calificación crediticia"}
                image={<img alt='' src={TwoHands} />}
              />
            </Col>
            <Col md={{ span: 3 }}>
              <SurgirItemCard 
                text={`Evaluación sencilla y rápida. Sin trámites ni papeleos engorrosos.`}
                width={95}
                title={"Crédito de fácil acceso"}
                image={<img alt='' src={HandMoney} />}
              />
            </Col>
            <Col md={{ span: 3 }}>
              <SurgirItemCard 
                text={`No te exigiremos ahorro inicial o mensual para obtener tu préstamo.`}
                width={95}
                title={"Préstamos sin ahorros"}
                image={<img alt='' src={Porki} />}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="last-segment-container">
        <div className="title-segment-content-solidario">
          <span className="title-general-segment">
            Entérate más acerca de tu préstamo
          </span>
        </div>
        <div className="body-segment-content-solidario-cw">
          {

            !mobileTab ?  <SurgirTabs tabs={TABS_CREDIT_WOMEN} /> : 
            <div className='container-acordion-responsive'>
              <SurgirAccordion 
                sections={TABS_CREDIT_WOMEN} 
              />
            </div>
          }
          
        
        </div>

        {
          !hiddenSection && <Container style={{ marginBottom: "96px" }}>
          <div className="title-segment-content-solidario">
            <span className="title-general-segment">
              No esperes más, haz SURGIR tu negocio ¡HOY!
            </span>
          </div>
          <div className="body-segment-content-solidario-cw">
            <Row>
              <Col md={4}>
                <ItemImage
                  image={First}
                  text={
                    <span className="parrafo">
                      Nuestra página web, haciendo clic{" "}
                      <LinkModi to="/" text="aquí" />
                    </span>
                  }
                />
              </Col>
              <Col md={4}>
                <ItemImage
                  image={Second}
                  text={
                    <span className="parrafo">
                      Nuestras sucursales de{" "}
                      <LinkModi to="/canales" text={" Los Olivos"} /> y{" "}
                      <LinkModi to="/canales" text={"San Juan de Lurigancho"} />.
                    </span>
                  }
                />
              </Col>
              <Col md={4}>
                <ItemImage
                  image={Third}
                  text={
                    <span className="parrafo">
                      O llamando al <br />{" "}
                      <a
                        href="tel:(01) 480 0123"
                        rel="noopener noreferrer"
                        style={{ color: "#257fa4" }}
                      >
                        (01) 480 0123
                      </a>
                    </span>
                  }
                />
              </Col>
            </Row>
          </div>
        </Container>
        }
        

      </div>
              
      <SurgirModalDownload
        title={'Simulador de crédito'}
        description="Selecciona el documento que deseas abrir y descargar."
        show={visibleModalSimulador}
        handleClose={handleModalSimuladorClose}
        documentList={[
          { Icon: XLS, document: process.env.REACT_APP_ARCHIVO_DE_SIMULADOR_MUJERES ?? '', downloadName: "Simulador_credito", name: 'Simulador de crédito (Descargar)' },
          { Icon: PDF, document: process.env.REACT_APP_ARCHIVO_DE_SIMULADOR_INFORMATIVO_MUJERES ?? '', downloadName: "Formula_ejemplos", name: 'Fórmulas y ejemplos explicativos (Descargar)' },
        ]}
      ></SurgirModalDownload>
    </div>
  )
}

export default CreditWomen;